.team {
    width: 100%;
    margin: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .team-title {
        width: 500px;
        margin-bottom: 50px;

        h2 {

        }

        p {
            
        }

        overflow-wrap: break-word;

        @media (max-width: 700px) {
            width: 100%;
        }
    }

    .team-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        
        width: 60%;

        .team-member-card {
            width: 250px;
            height: 321px;

            background-color: var(--background-color-light);
            border-radius: 7px;

            margin: 12px;

            cursor: pointer;

            &:hover {
                filter: brightness(1.1);
            }

            transition: .1s linear filter;
            
            .team-pfp {
                width: 250px;
                height: 250px;
                
                object-fit: cover;
                border-radius: 7px;

                display: block;
            }

            h3 {
                margin-top: 5px;

                font-size: 20px;
                font-family: "Mona-Sans Black Wide";
            }

            p {
                margin-top: 10px;

                font-family: "Mona-Sans Medium Wide";
                font-size: 12px;
            }
        }
    }
}
